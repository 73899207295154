import React, { Component, PureComponent } from 'react';
import './styles/App.css';
import axios from 'axios';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import ApiConfig from './config'

import Welcome from './Welcome/Welcome';
import Projects from './Project/Projects';
// import Record from './Project/Record';


function bindRouteParamsToProps() {
  return function(EmbedClass) {
    const klass = class extends PureComponent {
      render() {
        const routeParams = this.props.match.params || {};
        return <EmbedClass {...routeParams} {...this.props} />
      }
    };

    return klass;
  }
}
axios.defaults.headers.common['Authorization'] = 'Bearer ' + ApiConfig();


class App extends Component {
  // Render
  // ----------------------------------------------------
  render() {

    return (

      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={bindRouteParamsToProps()(Projects)} />
          <Route exact path='/:recordId' component={bindRouteParamsToProps()(Projects)} />
          <Route exact path='/login' component={bindRouteParamsToProps()(Welcome)} />

        </Switch>
      </BrowserRouter>

    );
  }
}

export default App;
