import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import RecordView from './RecordView';


class ListView extends Component {
  constructor(props) {
    super();
    this.state = {
      follows: [],
      ongoing: [],
      decision: [],
    }
  }

  splitUp = () => {
    console.log(this.props.data);

    let followsList = [];
    let ongoingList = [];
    let decisionList = [];
    for (var i in this.props.data) {

      let propedItem = this.props.data[i];
      let thisDate = new Date(propedItem.fields['Last Touch']);
      propedItem.fields['Last Touch'] = new Date(thisDate.getTime() + Math.abs(thisDate.getTimezoneOffset()*60000)); //fix the date
      let weekAgo = new Date(+new Date - 1000*60*60*24*7);
      weekAgo = new Date(weekAgo.getTime() + Math.abs(weekAgo.getTimezoneOffset()*60000)); //fix the date


      if (weekAgo > thisDate) {
        followsList.push(propedItem)
      } else if (this.props.data[i].fields['Status'] !== "Didn't Close" && this.props.data[i].fields['Status'] !== "Closed") {
        ongoingList.push(propedItem)
      } else {
        decisionList.push(propedItem)
      }
    }


    let finalOngoing = ongoingList.sort((a, b) => (a.fields['Last Touch'] > b.fields['Last Touch']) ? 1 : -1)


    console.log(finalOngoing);

    this.setState({
      ongoing: finalOngoing,
      decision: decisionList,
      follows:followsList,
    })
  }

  componentDidMount() {
    let splitThem = function() {
      console.log('loadAllData');
      if (this.props.data) {
        this.splitUp();
      } else {
        setTimeout((function() {
          splitThem();
          return;
        }).bind(this), 200);
      }
    }.bind(this)
    splitThem(); //run on load
  }

  // Render
  // ----------------------------------------------------
  render() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return (

      <div className="ListView">
        {this.messageModal}
        {this.recordModal}
        {this.importModal}

        <Link to="/new" class='btn'>Add Record</Link>

        <div className="ongoingList">
          {this.state.follows.length > 0 ? <h4>Follow Ups</h4> : ''}
          {this.state.follows ? this.state.follows.map((e,i) => {

            let theClass = '';
            if (e.fields['Status'] === 'No Meeting') {
              theClass = 'noMeeting';
            } else if (e.fields['Status'] === 'Pre Proposal') {
              theClass = 'noProposal';
            } else if (e.fields['Status'] === 'Proposal Sent') {
              theClass = 'proposal';
            }

            let today = new Date();

            let updateDate = new Date(e.fields['Last Touch']);
            let readableDate = monthNames[updateDate.getMonth()] + ' ' + updateDate.getDate();


            today = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate()
            updateDate = updateDate.getFullYear()+'/'+(updateDate.getMonth()+1)+'/'+updateDate.getDate()
            if (today === updateDate) {theClass = theClass + ' sent';}


            return (
              <div className={"ListItem card " + theClass}>
                <p className="tag">{e.fields['Status']}</p>
                <div className="nameBox">
                  <h2>{e.fields['Project Name']}</h2>
                  <h3>{e.fields['Name']}</h3>
                </div>
                <p className="touched">Last Touched - {readableDate}</p> <br />
                <div className="buttonBox">
                  <Link className="btn ghostIt" to={'/' + e.id}>View</Link>
                  <div className="btn sendMes" onClick={()=>this.props.toggleModal('message', e)}>Send Message</div>
                </div>
              </div>
            )
          }) : ''}
        </div>

        <div className="ongoingList">
          {this.state.ongoing.length > 0 ? <h4>Pipeline</h4> : ''}
          {this.state.ongoing ? this.state.ongoing.map((e,i) => {

            let theClass = '';
            if (e.fields['Status'] === 'No Meeting') {
              theClass = 'noMeeting';
            } else if (e.fields['Status'] === 'Pre Proposal') {
              theClass = 'noProposal';
            } else if (e.fields['Status'] === 'Proposal Sent') {
              theClass = 'proposal';
            }

            let today = new Date();

            let updateDate = new Date(e.fields['Last Touch']);
            let readableDate = monthNames[updateDate.getMonth()] + ' ' + updateDate.getDate();


            today = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate()
            updateDate = updateDate.getFullYear()+'/'+(updateDate.getMonth()+1)+'/'+updateDate.getDate()
            if (today === updateDate) {theClass = theClass + ' sent';}


            return (
              <div className={"ListItem card " + theClass}>
                <p className="tag">{e.fields['Status']}</p>
                <div className="nameBox">
                  <h2>{e.fields['Project Name']}</h2>
                  <h3>{e.fields['Name']}</h3>
                </div>
                <p className="touched">Last Touched - {readableDate}</p> <br />
                <div className="buttonBox">
                  <Link className="btn ghostIt" to={'/' + e.id}>View</Link>
                  <div className="btn sendMes" onClick={()=>this.props.toggleModal('message', e)}>Send Message</div>
                </div>
              </div>
            )
          }) : ''}
        </div>


        <div className="ongoingList">
          {this.state.decision.length > 0 ? <h4>Decision Made</h4> : ''}
          {this.state.decision ? this.state.decision.map((e,i) => {

            let theClass = '';
            if (e.fields['Status'] === "Didn't Close") {
              theClass = 'noClose';
            } else if (e.fields['Status'] === 'Closed') {
              theClass = 'closed';
            }
            return (
              <div className={"ListItem card " + theClass}>
                <p className="tag">{e.fields['Status']}</p>
                <div className="nameBox">
                  <h3>{e.fields['Project Name'] ? e.fields['Project Name'] + ' (' + e.fields['Name'] + ')' : e.fields['Name']}</h3>
                </div>
                <div className="buttonBox">
                  <Link className="btn ghostIt" to={'/' + e.id}>View</Link>
                </div>
              </div>
            )
          }) : ''}
        </div>

      </div>

    );
  }

  get messageModal() {
    if (this.props.modal === 'message') {
      let record = this.props.currentRecord;

      let logNotes = '';
      if (record.fields['Notes']) {
        logNotes = record.fields['Notes'].replace(/(?:\r\n|\r|\n)/g, '<br />');
      }
      setTimeout((function() {
        document.getElementById('logNotes').innerHTML = logNotes;
      }), 50);

      let touchDate = new Date(record.fields['Last Touch']);
      touchDate = touchDate.getMonth() + '/' + touchDate.getDate() + '/' + touchDate.getFullYear();

      let theModal = '';
      let theRec = {};
      if (this.props.recordView) {
        theModal = 'record';
        theRec = this.props.currentRecord;
      }

      return (
        <div className="modalWrapper">
          <div className="inner">
            <div className="modalInner">
              <form className="modalBody" onSubmit={(event)=>this.props.sendMessage(event)}>
                <h2>{record.fields['Project Name'] ? record.fields['Project Name'] : record.fields['Name']}</h2>
                <p>Last Touched: {touchDate}</p>

                {this.contactInfo}

                <p>Write a quick note to yourself about this message</p>
                <textarea id="note" placeholder="Notes..." />

                <div className="buttonRow">
                  <div className="btn ghostIt" onClick={()=>this.props.toggleModal(theModal, theRec)}>Cancel</div>
                  <button type="submit" className="btn">Message Sent</button>
                </div>
              </form>

              <div className="modalNotes">
                <p id="logNotes">{record.fields['Notes']}</p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return;
    }
  }

  get contactInfo() {
    let record = this.props.currentRecord;
    if (record.fields['Communication'] === 'Emails') {
      if (record.fields['Secondary Contact']) { //there's a secondary contact
        return (
          <div className="contactArea">
            <div className="contactItem">
              <p>Primary</p>
              <h3>{record.fields['Name']} <br /> {record.fields['Email']}</h3>
            </div>

            <div className="contactItem">
              <p>Secondary</p>
              <h3>{record.fields['Secondary Contact']} <br /> {record.fields['Secondary Email']}</h3>
            </div>
          </div>
        )
      } else {
        return (
          <div className="contactArea">
            <div className="contactItem">
              <p>Contact</p>
              <h3>{record.fields['Name']} <br /> {record.fields['Email']}</h3>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="contactArea">
          <div className="contactItem">
            <p>Contact</p>
            <h3>{record.fields['Name']}</h3>
          </div>

          <div className="contactItem">
            <p>{record.fields['Communication']} Username</p>
            <h3>{record.fields['DM Handle']}</h3>
          </div>
        </div>
      );
    }
  }

  get importModal() {
    if (this.props.modal === 'import') {
      return (
        <div className="modalWrapper">
          <div className="inner">
            <div className="modalInner">
              <form className="modalBody" onSubmit={(event)=>this.props.importRecord(event)}>
                <h2>Paste the text from our website form</h2>
                <textarea id="note" placeholder="Your Name..." />

                <div className="buttonRow">
                  <Link to="/new" className="btn ghostIt">Cancel</Link>
                  <button type="submit" className="btn">Import</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return;
    }
  }

  get recordModal() {
    if (this.props.modal === 'record') {
      return (
        <RecordView
          recordChanges={this.props.recordChanges}
          currentRecord={this.props.currentRecord}
          changeRecordHandler={this.props.changeRecordHandler}
          toggleModal={this.props.toggleModal}
          saveRecord={this.props.saveRecord}
        />
      );
    } else {
      return;
    }
  }
}

export default ListView;
