import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class RecordView extends Component {

  // Render
  // ----------------------------------------------------
  render() {
    const { currentRecord, recordChanges } = this.props;
    let fields = currentRecord.fields;

    let incomingDate = new Date();
    if (currentRecord && currentRecord.fields['Last Touch']) {
      incomingDate = new Date(currentRecord.fields['Last Touch']);
    }
    incomingDate = incomingDate.toISOString().split('T')[0];

    return (
      <div className="modalWrapper">
        <div className="inner record">
          <div className="modalNav">
            <h2>{fields['Project Name'] ? fields['Project Name'] : fields['Name']}</h2>
            <div className={currentRecord.id ? 'txtBtn isHidden' : 'txtBtn'} onClick={()=>this.props.toggleModal('import')}>Import</div>

            <div className="navBtns">
              <div className={recordChanges ? "btn save" : "btn save isHidden"} onClick={this.props.saveRecord}>Save Changes</div>


              <Link to="/" className="btn exit">Exit</Link>
            </div>
          </div>
          <div className="modalBody">
            <form className="form">




              <div className="card">
                <div className="inputBlock">
                  <label>Project Name</label>
                  <input
                    type="text"
                    value={fields['Project Name']}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Project Name')}
                   />
                </div>
                <div className="inputBlock--third">
                  <label>Budget</label>
                  <input
                    type="text"
                    value={fields['Budget']}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Budget')}
                   />
                </div>

                <div className="inputBlock--third">
                  <label>Source</label>
                  <select
                    value={fields['Source']}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Source')}
                   >
                     <option>Website</option>
                    <option>TikTok</option>
                    <option>Instagram</option>
                    <option>Referral</option>
                    <option>Other</option>
                  </select>
                </div>

                <div className="inputBlock--third">
                  <label>Communication</label>
                  <select
                    value={fields['Communication']}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Communication')}
                   >
                    <option>TikTok</option>
                    <option>Instagram</option>
                    <option>Email</option>
                  </select>
                </div>
              </div>


              <div className="card">

                <div className="inputBlock">
                  <label>Status</label>
                  <select
                    value={fields['Status']}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Status')}
                   >
                    <option>No Meeting</option>
                    <option>Pre Proposal</option>
                    <option>Proposal Sent</option>
                    <option>Didn’t Close</option>
                    <option>Closed</option>
                 </select>
                </div>
                <div className="inputBlock--half">
                  <label>Last Touched</label>
                  <input
                    type="date"
                    value={incomingDate}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Last Touch')}
                   />
                </div>
                <div className="inputBlock--half">
                  <label>Total Touches</label>
                  <input
                    type="number"
                    value={fields['Total Touches']}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Total Touches')}
                   />
                </div>

                <div className='btn' onClick={()=>this.props.toggleModal('message', currentRecord)}>Send Message</div>

              </div>

              <div className="cardRow">

                <div className="card">
                  <div className="inputBlock">
                    <label>Primary Contact</label>
                    <input
                      type="text"
                      value={fields['Name']}
                      onChange={(e) => this.props.changeRecordHandler(e, 'Name')}
                     />
                  </div>

                  <div className="inputBlock--half">
                    <label>Primary Email</label>
                    <input
                      type="text"
                      value={fields['Email']}
                      onChange={(e) => this.props.changeRecordHandler(e, 'Email')}
                     />
                  </div>

                  <div className="inputBlock--half">
                    <label>{fields['Communication'] === 'Emails' ? 'Social' : fields['Communication'] } Username</label>
                    <input
                      type="text"
                      value={fields['DM Handle']}
                      onChange={(e) => this.props.changeRecordHandler(e, 'DM Handle')}
                     />
                  </div>
                </div>



                <div className="card">
                  <div className="inputBlock">
                    <label>Secondary Contact</label>
                    <input
                      type="text"
                      value={fields['Secondary Name']}
                      onChange={(e) => this.props.changeRecordHandler(e, 'Secondary Contact')}
                     />
                  </div>

                  <div className="inputBlock">
                    <label>Secondary Email</label>
                    <input
                      type="text"
                      value={fields['Secondary Email']}
                      onChange={(e) => this.props.changeRecordHandler(e, 'Secondary Email')}
                     />
                  </div>
                </div>

              </div>
            </form>
            <div className="notes">
              <label>Notes</label>
              <textarea
                value={fields['Notes']}
                onChange={(e) => this.props.changeRecordHandler(e, 'Notes')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecordView;
