import React, { Component } from 'react';
import '../styles/App.css';
import axios from 'axios';
// import bcrypt from 'bcrypt';
// import { Base64 } from 'js-base64';

import ListView from './ListView';

let finalURL;

export default class Projects extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      error: "",
      data: null,
      dataURL: 'https://api.airtable.com/v0/',
      baseId: 'appenIK1yknTLVwqC',
      listView: 'view=All',
      currentTable: 'Data',
      currentRecord: [],
      currentRecordIndex: [],
      currentId: [],
      fallbackRecord: [],
      recordView: false,
      activeModal: false,
      modalType: '',
      dataOffset: '',
      userName: '',
      newRecord: false,
      recordChanges: false,
      listIsVisible: props.recordId == null,
      preview: false,
      isAdmin: true,
      docModal: false,
      documents: [],
      emailModal: false,
      modal: '',
    }
  }

  // handleSubmit = () => {
  //
  //   let emailData = {
  //     template_id:"[template_id]"
  //
  //     from: 'racosta@crodconstrucciones.com', // Change to your verified sender
  //     subject: 'CROD Construcciones Projecto Update',
  //     text: 'and easy to do anywhere, even with Node.js',
  //     personalizations:[
  //       {
  //         to: this.props.currentRecord['Client Information'][0].email
  //         dynamic_template_data: {
  //           "project": this.props.currentRecord['Project'],
  //           "featured": this.props.currentRecord['Images'][this.props.currentRecord['Images'].length - 1].url,
  //           "id": this.props.currentId,
  //         }
  //       }
  //     ],
  //   };
  //
  //   fetch('/api/form-submit-url', {
  //     method: 'POST',
  //     body: data,
  //   });
  // }


  componentWillUpdate = (nextProps, nextState) => {
    if (this.state.loading && !nextState.loading) {
      if (this.props.recordId != null) {
        if (this.props.recordId === 'new') {
          console.log('new-1');
            let today = new Date()
            today = (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear();
            let emptyRecord = {
              fields: {
                "Last Touch": today,
                "Project Name": 'New Project',
                "Status":"No Meeting",
                "Communication":'Emails',
                "Total Touches": 1,
              }
            }

            this.setState({
              modal: 'record',
              recordView: true,
              currentRecord: emptyRecord,
              currentId: 'new',
              recordChanges: true,
            })
        } else if (this.props.recordId === 'documents') {
          console.log('docs');

          finalURL = this.state.dataURL + this.state.baseId + '/CROD Documents/';
          return axios
            .get(finalURL)
            .then(response => {
              console.log(response);
              this.setState({
                loading: false,
                error: false,
                documents: response.data.records,
                recordView: false,
                docModal: true,
                currentRecord: undefined,
              });
            })
            .catch(error => {
              console.error("error: ", error);
              this.setState({
                error: `${error}`,
                loading: false,
              });
            });
        } else {
          console.log('yo-1');
          if (nextState.data != null && nextState.data.filter(e => e.id === this.props.recordId)[0]) {

            let record = nextState.data.filter(e => e.id === this.props.recordId)[0];

            setTimeout((function() {
              this.setState({
                modal: 'record',
                recordView: true,
                currentRecord: record,
                currentId: record.id,
              })
            }).bind(this), 20);
          } else {
            finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '/' + this.props.recordId;
            return axios
              .get(finalURL)
              .then(response => {
                this.setState({
                  recordView: true,
                  loading: false,
                  error: false,
                  // noteCharacters: response.data.fields['Notes'].length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  currentRecord: response.data.fields,
                });
              })
              .catch(error => {
                this.setState({
                  error: true,
                  message: "404, no record",
                  loading: false,
                });
              });
          }
        }
      } else {
        console.log('yo-3');
        finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '/';
        return axios
          .get(finalURL)
          .then(response => {
            console.log(response);
            this.setState({
              recordView: false,
              loading: false,
              error: false,
              data: response.data.records,
              currentRecord: undefined,
            });
          })
          .catch(error => {
            console.error("error: ", error);
            this.setState({
              error: `${error}`,
              loading: false,
            });
          });
      }
    }
  }


  toggleModal = (e, rec) => {
    this.setState({
      modal: e,
    });

    if (rec) {
      this.setState({
        currentRecord: rec,
        currentId: rec.id,
      })
    }
  }


  updateItem = e => {
    this.setState({
      currentRecord: e,
      recordChanges: true,
    })
  }


  navExit = () => {
    if (this.state.recordChanges) {
      this.toggleModal();
    } else {
      this.props.history.push('/app/');
    }
  }

  saveRecord = () => {
    let pushRecord = this.state.currentRecord.fields;
    if (pushRecord['Last Touch']) {
      let lastTouch = new Date(pushRecord['Last Touch']);
      lastTouch = new Date(lastTouch.getTime() + Math.abs(lastTouch.getTimezoneOffset()*60000)); //fix the date
      pushRecord['Last Touch'] = lastTouch.getFullYear()+'/'+(lastTouch.getMonth()+1)+'/'+lastTouch.getDate();
    }
    let pushRecordId = this.state.currentId;
    let finalPush = {"fields": pushRecord}


    if (this.state.currentId === 'new') {
      console.log(finalPush);
      axios
      .post(this.state.dataURL + this.state.baseId + '/' + this.state.currentTable, finalPush)
      .then(response => {
        alert('Record Saved!');
        this.props.history.push('/' + response.data.id);
        window.location.reload();
      });
    } else {
      axios
      .put(this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '/' + pushRecordId, finalPush)
      .then(response => {
        alert('Record Saved');
      });
    }
  }

  changeRecordHandler = (e, thisKey) => {
    let currentRecordState = this.state.currentRecord;
    currentRecordState.fields[thisKey] = e.target.value;

      this.setState({
        currentRecord: currentRecordState,
        recordChanges: true,
      })
  };



  importRecord = (e) => {
    e.preventDefault();
    let theImport = e.target[0].value.split('\n');
    let answers = [];
    for (var i in theImport) {
      answers.push(theImport[i].split(': ')[1]);
    }

    let propedToday = new Date()
    propedToday = (propedToday.getMonth()+1) + '/' + propedToday.getDate() + '/' + propedToday.getFullYear();

    let noteStamp = '';
    let dayTime = '';
    let today = new Date()
    if (today.getHours() > 12) {
      if (today.getMinutes() < 10) {
        dayTime = (today.getMonth()+1) + "/" + today.getDate()  + "/" + today.getFullYear() + " " + (today.getHours() - 12) + ":0" + today.getMinutes() + " PM";
      } else {
        dayTime = (today.getMonth()+1) + "/" + today.getDate()  + "/" + today.getFullYear() + " " + (today.getHours() - 12) + ":" + today.getMinutes() + " PM";
      }
    } else {
      if (today.getMinutes() < 10) {
        dayTime = (today.getMonth()+1) + "/" + today.getDate()  + "/" + today.getFullYear() + " " + today.getHours() + ":0" + today.getMinutes() + " AM";
      } else {
        dayTime = (today.getMonth()+1) + "/" + today.getDate()  + "/" + today.getFullYear() + " " + today.getHours() + ":" + today.getMinutes() + " AM";
      }
    }
    noteStamp = dayTime + '\n';
    noteStamp += 'Services -';
    if (answers[5] === 'true') {
      noteStamp += ' Prototype';
    }
    if (answers[6] === 'true') {
      noteStamp += ' Validation';
    }
    if (answers[7] === 'true') {
      noteStamp += ' Full-Design';
    }
    if (answers[8] === 'true') {
      noteStamp += ' App-Development';
    }
    if (answers[9] === 'true') {
      noteStamp += ' Website';
    }
    if (answers[10] === 'true') {
      noteStamp += ' Marketing';
    }
    noteStamp += '\n\nMessage - ' + answers[3];

    let newRec = {
      "Communication": "Emails",
      "Source": "Website",
      "Email": answers[1],
      "Last Touch": propedToday,
      "Name": answers[0],
      "Project Type": answers[2],
      "Status": "No Meeting",
      "Total Touches": 0,
      "Budget": answers[4],
      "Notes": noteStamp,
    }


    let curRec = this.state.currentRecord;
    curRec.fields = newRec;

    this.setState({
      currentRecord: curRec,
    })


    setTimeout((function() {
      this.saveRecord();
    }).bind(this), 0);
  };



  loadData = () => {
    this.setState({
      loading: true
    });

    //initial load
    setTimeout((function() {
      finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '?' + this.state.listView;

      if (this.state.dataOffset !== '') {
        finalURL = finalURL + '&offset=' + this.state.dataOffset;
      }

      console.log('loadData()');
      return axios
      .get(finalURL)
      .then(response => {
        this.setState({
          data: response.data.records,
          //put it here
          loading: false,
          error: false,
          loadingMore: true,
          dataOffset: response.data.offset,
        });
        setTimeout((function() {
          this.setState({
            loadingMore: false,
          });

          if (this.state.recordView) {
            if (this.state.preview) {
              document.title = "Previewing " + this.state.currentRecord['Project'] + " | CROD Construcciones"
            } else {
              document.title = "Editing " + this.state.currentRecord['Project'] + " | CROD Construcciones"
            }
          } else {
            document.title = "Admin Panel | CROD Construcciones";
          }

        }).bind(this), 100);
      })
      .catch(error => {
        console.error("error: ", error);
        this.setState({
          error: `${error}`,
          loading: false,
        });
      });
    }).bind(this), 10);
  };


  logoutHandler = () => {
    sessionStorage.clear();
    localStorage.clear();

    this.props.history.push('/');
  }


  sendMessage = (e) => {
    e.preventDefault();

    let today  = new Date();
    let dayTime;
    if (today.getHours() > 12) {
      if (today.getMinutes() < 10) {
        dayTime = (today.getMonth()+1) + "/" + today.getDate()  + "/" + today.getFullYear() + " " + (today.getHours() - 12) + ":0" + today.getMinutes() + " PM";
      } else {
        dayTime = (today.getMonth()+1) + "/" + today.getDate()  + "/" + today.getFullYear() + " " + (today.getHours() - 12) + ":" + today.getMinutes() + " PM";
      }
    } else {
      if (today.getMinutes() < 10) {
        dayTime = (today.getMonth()+1) + "/" + today.getDate()  + "/" + today.getFullYear() + " " + today.getHours() + ":0" + today.getMinutes() + " AM";
      } else {
        dayTime = (today.getMonth()+1) + "/" + today.getDate()  + "/" + today.getFullYear() + " " + today.getHours() + ":" + today.getMinutes() + " AM";
      }
    }
    let currRec = this.state.currentRecord;

    let newNote;
    newNote = dayTime + '\n' + document.getElementById('note').value;

    if (currRec.fields['Notes']) {
      currRec.fields['Notes'] = newNote + '\n\n' + currRec.fields['Notes'];
    } else {
      currRec.fields['Notes'] = newNote;
    }

    currRec.fields['Last Touch'] = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();

    if (currRec.fields['Total Touches']) {
      currRec.fields['Total Touches'] = currRec.fields['Total Touches'] + 1;
    } else {
      currRec.fields['Total Touches'] = 1;
    }


    this.setState({
      currentRecord: currRec,
    })

    setTimeout((function() {
      this.saveRecord();
    }).bind(this), 0);
  }

  //
  //
  // checkLogin = () => {
  //   //update this!
  //   let finalURL = this.state.dataURL + this.state.baseId + '/Admins';
  //   console.log(finalURL);
  //
  //   return axios
  //     .get(finalURL)
  //     .then(response => {
  //       this.setState({
  //         checkData: response.data.records,
  //       });
  //
  //
  //       setTimeout((function() {
  //         console.log(this.state.checkData);
  //         let userRecord;
  //         if (this.state.checkData.filter(user => user.fields['Email'] === localStorage.admin)[0]) {
  //           userRecord = this.state.checkData.filter(user => user.fields['Email'] === localStorage.admin)[0];
  //           console.log(userRecord);
  //           this.loadData();
  //         } else {
  //           this.logoutHandler();
  //         }
  //       }).bind(this), 0);
  //     })
  //     .catch(error => {
  //       console.error("error: ", error);
  //       this.setState({
  //         error: `${error}`,
  //       });
  //     });
  // }




  componentDidMount() {
    // this.checkLogin();
    this.loadData();
    // if (localStorage.getItem('isLogged')  !== 'true' || localStorage.getItem('isAdmin')  !== 'true') {
    //   this.props.history.push('/login');
    // } else {
    // }

    // this.loadData();
  }


    // Render
    // ----------------------------------------------------
    render() {
      return (
        <div className="Projects">
          {this.viewType}
        </div>
      );
    }

    get viewType() {
      if (this.state.error) {
        return (
          <div className="error">
            <h1>{this.state.message}</h1>
            <p>Click the x in the top left</p>
          </div>
        );
      } else {
        return (
          <ListView
            data={this.state.data}
            toggleModal={this.toggleModal}
            modal={this.state.modal}
            currentRecord={this.state.currentRecord}
            sendMessage={this.sendMessage}
            recordView={this.state.recordView}
            changeRecordHandler={this.changeRecordHandler}
            recordChanges={this.state.recordChanges}
            saveRecord={this.saveRecord}
            importRecord={this.importRecord}
          />
        );
      }
    }
  }
